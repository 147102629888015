.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .home-image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .home-text {
    text-align: start;
    padding: 20px;
  }

  @media (min-width: 768px) {
    .home-content {
      flex-direction: row;
      align-items: flex-start;
    }

    .home-image {
      margin-Top:20px;
      width: 50%;
    }

    .home-text {
      margin-Left: 40px;
      width: 50%;
      text-align: left;
      padding: 20px;
    }
  }