a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 16px;
}
@media (min-width: 768px) {
  html {
    font-size: 18px;
  }
}

.nav-menu {
  position: relative;
  top: -55px;
  right: 120px;
  width: 50px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  float: right;
  z-Index: 1000;
}

@media (max-width: 767px) {
  .nav-menu {
    right: 30px;
  }
}

.nav-item {
  margin-right: 60px; /* Increase distance between nav items */
  position: relative; /* Add positioning */
}

.nav-item::after {
  content: '•'; /* Add a dot */
  position: absolute;
  top: 50%; /* Center vertically */
  right: -30px; /* Position the dot */
  transform: translateY(-50%); /* Center vertically */
}

@media (max-width: 767px) {
  .nav-item::after {
    content: ''; /* Remove the dot */
  }
}

.nav-item:last-child::after {
  content: ''; /* Remove dot after the last item */
}

.center-logo {
  margin-top: 50px;
  width: 60%;
  max-width: 350px;
}

.nav-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 768px) {
  .nav-menu-container {
      flex-direction: column;
  }
}