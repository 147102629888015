/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.global-button {
  width: 100%;
  padding: 7px;
  background-color: #cfcfcf; /* replace with your desired color */
  color: #666666;
  border: 1px solid #cfcfcf;
  outline: none;
  /* add any other styles you want for your buttons */
}

.global-button:disabled {
  background-color: #EBEAE5;
  color: #a6a39d;
  border: 1px solid #a6a39d;
}

.global-button.deselected {
  width: 100%;
  padding: 7px;
  background-color: #E8E8E8; 
  color: #666666; 
  border: 1px solid #cfcfcf;
  outline: none;
}

.global-button-white {
  width: 100%;
  padding: 7px;
  background-color: #EBEAE5; /* replace with your desired color */
  color: #a6a39d;
  border: 1px solid #a6a39d;
  outline: none;
  /* add any other styles you want for your buttons */
}

.bold-list {
  list-style-type: none;
  counter-reset: item;
}

.bold-list .bold-list-item {
  counter-increment: item;
}

.bold-list .bold-list-item::before {
  content: counter(item) ". ";
  font-weight: 400;
}

.bold-list-item {
  margin-bottom: 20px; /* Adjust this value to add more or less space */
}

/*@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
}
p{
  font-family: 'Roboto', sans-serif;
  font-weight: 200;
}
h1 {
  font-family: 'Roboto Slab', serif;
}*/

@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap');
h1 {
  font-family: "Hanken Grotesk", sans-serif;
  font-weight: 300;
  color: #767676;
  font-size: 30px;
}
h2 {
  font-family: "Hanken Grotesk", sans-serif;
  font-weight: 300;
  color: #767676;
  font-size: 26px;
}
h3 {
  font-family: "Hanken Grotesk", sans-serif;
  font-weight: 300;
  color: #767676;
  font-size: 22px;
}
p {
  font-family: "Hanken Grotesk", sans-serif;
  font-weight: 250;
  color: #767676;
  font-size: 18px;
}
body {
  font-family: "Hanken Grotesk", sans-serif;
  font-weight: 250;
  color: #767676;
  font-size: 18px;
}